<template>
  <div class="event-management">
    <p class="event-management__title">{{ $t('events') }}</p>
    <div class="event-management__right">
      <div class="event-management__right-header">
        <ui-input search v-model="search" @input="updateEventsList" :placeholder="$t('search')"/>
        <ui-date-picker v-model="date" isRange @input="updateEventsList" :eventsDates="getEventsDates"/>
      </div>
      <ui-loader v-model="visibleLoader"/>
      <div class="event-management__right-cards" v-if="!visibleLoader && getEvents.length">
        <event-management-card v-for="(item, key) in getEvents" :key="key" :data="item"/>
      </div>
      <div v-if="!visibleLoader && !getEvents.length" class="empty">
        {{ $t('empty') }}
      </div>
      <ui-button v-if="visibleLoadMore" color="white" @click="loadMore">{{ $t('show-more') }}</ui-button>
    </div>
  </div>
</template>

<script>
import EventManagementCard from "@/components/template/event/event-management/EventManagementCard.vue";
import UiInput from "@/components/ui/UiInput.vue";
import UiDatePicker from "@/components/ui/UiDatePicker.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiLoader from "@/components/ui/UiLoader.vue";
import UiButton from "@/components/ui/UiButton.vue";
import {getDate} from "@/utils/general";

export default {
  name: "EventManagement",
  components: {UiButton, UiLoader, UiDatePicker, UiInput, EventManagementCard},
  data () {
    return {
      search: '',
      date: null,
      visibleLoader: true,
      visibleLoadMore: true
    }
  },

  methods: {
    ...mapActions(['events', 'eventsDates']),
    ...mapMutations(['setEvents']),
    callEventsApi() {
      this.visibleLoader = true
      this.events({
        skip: 0,
        take: 21,
        search: this.search,
        dateFrom: this.date ? getDate(this.date[0]) : this.date,
        dateTo: this.date ? getDate(this.date[1]) : this.date,
      }).then((res) => {
        this.eventsDates({
          skip: 0,
          take: 21,
          search: this.search,
          dateFrom: this.date ? getDate(this.date[0]) : this.date,
          dateTo: this.date ? getDate(this.date[1]) : this.date,
        })
        this.visibleLoader = false
        this.setEvents(res.data)
        if (res.meta.total === this.getEvents.length) {
          this.visibleLoadMore = false
        }
      })
    },

    updateEventsList() {
      this.visibleLoader = true
      this.events({
        search: this.search,
        dateFrom: this.date ? getDate(this.date[0]) : this.date,
        dateTo: this.date ? getDate(this.date[1]) : this.date,
        skip: 0,
        take: 21
      }).then((res) => {
        this.eventsDates({
          search: this.search,
          skip: 0,
          take: 21
        })
        this.visibleLoader = false
        this.visibleLoadMore = true
        this.setEvents(res.data)
        console.log(res.meta.total === this.getEvents.length)
        if (res.meta.total === this.getEvents.length) {
          this.visibleLoadMore = false
        }
      })
    },

    loadMore() {
      this.visibleLoader = true
      this.events({
        search: this.search,
        dateFrom: this.date ? getDate(this.date[0]) : this.date,
        dateTo: this.date ? getDate(this.date[1]) : this.date,
        skip: this.getEvents.length,
        take: 21
      }).then((res) => {
        this.eventsDates({
          search: this.search,
          skip: this.getEvents.length,
          take: 21
        })
        this.visibleLoader = false
        this.setEvents(this.$store.state.eventsStore.events.concat(res.data))
        if (res.meta.total === this.getEvents.length) {
          this.visibleLoadMore = false
        }
      })
    }
  },

  computed: {
    ...mapGetters(['getEvents', 'getEventsDates'])
  },

  mounted() {
    this.callEventsApi()
  }
}
</script>

<style lang="scss" scoped>
.event-management {
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  gap: 20px;

  @media (max-width: 1200px) {
    flex-direction: column;
    padding: 104px 20px 50px 20px;
  }

  &__title {
    color: #1B1A1F;
    font-size: 20px;
    padding-top: 10px;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1000px;
    width: 100%;

    &-header {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    &-cards {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
    }
  }
}

::v-deep .mx-input-wrapper {
  opacity: 0;
  z-index: 10;
  cursor: pointer;

  input {
    cursor: pointer !important;
  }
}
::v-deep .ui-date-picker {
  width: 32px;
  height: 32px;
}
::v-deep .ui-date-picker__inner-icon {
  width: 100%;
  height: 100%;
  bottom: 14px;
  cursor: pointer;
}
</style>
