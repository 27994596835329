<template>
    <router-link :to="`/event-management/${data.id}`" class="event-management-card card" :style="{backgroundImage: `url(${data.imageSrc.fileSrc || require('@/assets/images/event-card.png')})`}">
      <div class="card__backdrop"></div>
      <div class="card__header">{{ getFullDate(data.startDate).slice(0, 10) }}</div>
      <div class="card__footer">
        <div class="card__footer-left">
          <p>{{ data.name }}</p>
          <span>{{ data.place }}</span>
        </div>
      </div>
    </router-link>
</template>

<script>
import {getFullDate} from "../../../../utils/general";

export default {
  name: "EventManagementCard",
  methods: {getFullDate},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 490px;
  width: 100%;
  min-height: 250px;
  max-height: 251px;
  padding: 20px 20px 30px 20px;
  position: relative;

  &__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    z-index: 1;
  }

  &__header {
    border-radius: 200px;
    background: #E21F1F;
    box-shadow: 2px 2px 10px 0px rgba(153, 153, 153, 0.20);
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Gotham Pro Regular';
    font-weight: 400;
    padding: 12px 18px;
    z-index: 2;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    z-index: 2;

    p {
      color: #FFFFFF;
      font-size: 20px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;

      span {
        color: #FFFFFF;
        font-size: 14px;
        line-height: 120.2%;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 7px;
        font-family: 'Gotham Pro Regular';

        ::v-deep p {
          font-family: 'Gotham Pro Medium';
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>